// NPM Modules
import React, {lazy, Suspense} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

// CSS
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Pages and Components
import NavBar from './components/navbar/navbar';
//import FourOhFour from './components/404/404';
//import Contact from './pages/contact/contact';
import Footer from './components/footer/footer';
import Home from './pages/home/home';
//import Reviews from './pages/reviews/reviews';
//import Lighting from './pages/lighting/lighting';

// Lazy Components
const FourOhFour = lazy(() => import('./components/404/404'));
const Contact = lazy(() => import('./pages/contact/contact'));
const Reviews = lazy(() => import('./pages/reviews/reviews'));
const Lighting = lazy(() => import('./pages/lighting/lighting'));
const Landscaping = lazy(() => import('./pages/landscaping/landscaping'));
const Drafting = lazy(() => import('./pages/drafting/drafting'));

function App() {
  return (
    <div>
      <NavBar></NavBar>
      <Router>
        <Suspense fallback={<div className="center topPad"><Spinner animation="grow" variant="primary" className="spinner" style={{ height: "100px", width: "100px" }} /></div>}>
        <Switch>
          <Route exact path='/'>
            <Home></Home>
          </Route>
            <Route exact path='/lighting'>
              <Lighting></Lighting>
            </Route>
            <Route exact path='/landscaping'>
              <Landscaping></Landscaping>
            </Route>
            <Route exact path='/drafting'>
              <Drafting></Drafting>
            </Route>
          <Route exact path='/reviews'>
            <Reviews></Reviews>
          </Route>
            <Route exact path='/contact'>
              <Contact></Contact>
            </Route>
            <Route>
              <FourOhFour></FourOhFour>
            </Route>
        </Switch>
        </Suspense>
      </Router>
      <Footer></Footer>
    </div>
  );
}

export default App;
