import React, { Component } from 'react';
import './home.css';
import { Carousel, Card, CardDeck, Alert, Button } from 'react-bootstrap';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state={
            mobile: false,
            padding: '20vw',
            width: '90vw'
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        if (window.innerWidth < 767) {
            this.setState({ mobile: true, padding: '5vw', width: '90vw' });
        } else {
            this.setState({ mobile: false, padding: '20vw', width: '50vw' });
        }
    }

    render() {
        return (
            <div>
                <div>
                    <Carousel className="center">
                        <Carousel.Item>
                            <div id="carosel0">
                            </div>
                            <Carousel.Caption>
                                <h3>Home Lighting</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div id="carosel1">
                            </div>
                            <Carousel.Caption>
                                <h3>Home Lighting</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div id="carosel2">
                            </div>
                            <Carousel.Caption>
                                <h3>Yard Lighting</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div id="carosel3">
                            </div>
                            <Carousel.Caption>
                                <h3>Landscaping</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div style={{ paddingTop: '3vh', paddingLeft: this.state.padding, paddingRight: this.state.padding}}>
                    <Alert variant="info">
                        <Alert.Heading style={{ textAlign: 'center' }}>Crescent Landscape Lighting</Alert.Heading>
                        <p>
                            Based in Charleston SC, Crescent Landscape Lighting specializes in outdoor landscape and architectural lighting to provide a soothing ambient atmosphere as well as providing lighting sources for safety and security. Focusing on enhancing architectural features, main appearance plant material, hardscapes, water features, outdoor living space, and many other landscape and garden features.
                        </p>
                        <h4 className="center">Serving the Charleston SC metro area and Charlotte NC metro area:<br></br><br></br> "Bringing art to light"</h4>
                    </Alert>
                </div>
                <div className="homeCards" style={{ paddingLeft: '5vw', paddingRight: '5vw'}}>
                    <CardDeck>
                        <Card border="primary">
                            <div id="card1"></div>
                            <Card.Body>
                                <Card.Title className="center">Lighting</Card.Title>
                                <Card.Text>
                                    Installation of new landscape lighting systems, upgrading existing systems, RGB applications, WiFi and Bluetooth controlled lights, and much more.
                                </Card.Text>
                                <Card.Text className="right"><Button variant="primary" href="/lighting">Learn More about Lighting</Button></Card.Text>
                            </Card.Body>
                        </Card>
                        <Card border="warning">
                            <div id="card2"></div>
                            <Card.Body>
                                <Card.Title className="center">Landscaping</Card.Title>
                                <Card.Text>
                                    Installation and renovation of new and existing landscapes with a focus on artistic landscape features, gardens and hardscapes. 
                                </Card.Text>
                                <Card.Text className="right"><Button variant="warning" href="/landscaping">Learn More about Landscaping</Button></Card.Text>
                            </Card.Body>
                        </Card>
                        <Card border="dark">
                            <div id="card3"></div>
                            <Card.Body>
                                <Card.Title className="center">Drafting</Card.Title>
                                <Card.Text>
                                    Creating working landscape plans, themed garden designs, scaled landscape designs, water feature concepts, and outdoor living space design.
                                </Card.Text>
                                <Card.Text className="right"><Button variant="dark" href="/drafting">Learn More about Drafting</Button></Card.Text>
                            </Card.Body>
                        </Card>
                    </CardDeck>
                </div>
            </div>
        )
    }
}

export default Home;