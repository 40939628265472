import React, { Component } from 'react';
import './navbar.css';
import { Navbar, Nav } from 'react-bootstrap';

class NavBar extends Component {
    render() {
        return (
            <div style={{ paddingBottom: '56px'}}>
                <Navbar fixed="top" collapseOnSelect expand="md" bg="light" variant="light">
                    <Navbar.Brand href="/"><img src="logo.jpg" height="30px" width="30px" alt="Crescent Landscape Lighting Logo"/><span className="mobileTitle">    Crescent Landscape Lighting</span></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/lighting">Lighting</Nav.Link>
                            <Nav.Link href="/landscaping">Landscaping</Nav.Link>
                            <Nav.Link href="/drafting">Drafting</Nav.Link>
                            {/* <Nav.Link href="/reviews">Reviews</Nav.Link> */}
                        </Nav>
                        <Nav>
                            <Nav.Link eventKey={2} href="/contact">Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        )
    }
}

export default NavBar;