import React, { Component } from 'react';
import './footer.css';
import { Button, Image } from 'react-bootstrap';
import { FaPhoneAlt } from 'react-icons/fa';

class Footer extends Component {
    render() {
        return (
            <div className="paddedFooter center">
                <Image src="/logo.jpg" height="100px" width="100px" alt="Crescent Landscape Lighting Logo" style={{paddingBottom: '10px'}}></Image>
                <p className="footerText">Copyright {new Date().getFullYear()} Crescent Landscape Lighting</p>
                <Button href="tel:+18433027321" className="callButton" size="sm"><FaPhoneAlt style={{fontSize: '20px'}}/>{'     '}Give us a call!</Button>
            </div>
        )
    }
}

export default Footer;